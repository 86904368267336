import { Collapse } from 'bootstrap'
// import initDarkMode from './components/darkmode'
// import initTinyMce from './components/tinymce'
// import initToolTips from './components/tooltips'
// import initForms from './components/forms'
// import initGitlab from './modules/gitlab'
// import initRedmine from './modules/redmine'
// import initInvoice from './modules/invoice'
// import initLetter from './modules/letter'
// import initSettings from './modules/settings'
// import initStatistics from './modules/statistics'

document.addEventListener('DOMContentLoaded', () => {
	'use strict'

	// initDarkMode()
	// initTinyMce(tinymce)
	// initToolTips()
	// initForms(tinymce)
	// initGitlab()
	// initRedmine()
	// initInvoice()
	// initLetter()
	// initSettings()
	// initStatistics()

})
